#cboxWrapper {
  #cboxLoadedContent {
    .loyalty_popover {
      &__checkbox-container {
        .for-styled-checkbox {
          width: 100%;
        }
      }
    }
  }
}

.page-footer {
  .email-signup-block {
    &__form-content {
      &-fields {
        display: flex;
        flex-flow: row wrap;
        .email-signup-block {
          &__form-pc-email-promotions {
            order: -1;
            margin-#{$ldirection}: 2px;
            @media #{$large-up} {
              bottom: 92%;
            }
            @media #{$cr19-medium-portrait-only} {
              bottom: 70%;
            }
            @media #{$cr19-medium-landscape-only} {
              bottom: 68%;
            }
          }
          &__form-email,
          &__form-cta {
            margin-top: 5%;
            @media #{$medium-up} {
              margin-top: 0;
            }
          }
        }
      }
      .email-signup-block {
        &__form-messages {
          position: absolute;
        }
      }
    }
  }
}
