.sticky-chat {
  -moz-transition: bottom 0.2s ease-in;
  -o-transition: bottom 0.2s ease-in;
  -webkit-transition: bottom 0.2s ease-in;
  transition: bottom 0.2s ease-in;
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  z-index: 999999;
  #{$ldirection}: auto;
  #{$rdirection}: 20px;
  width: 280px;
  bottom: -361px;
  background-color: $color-white;
  border: 1px solid #d6d6d6;
  border-radius: 5px 5px 0 0;
  box-shadow: 0px 0px 16px 3px rgba(0, 0, 0, 0.2);
  color: #000000;
  @include swap_direction(padding, 0 0 4px);
  text-align: center;
  height: 400px;
  max-width: 336px;
  a {
    text-decoration: none;
  }
  &.expanded {
    bottom: 0 !important;
  }
}

h3.sticky-chat__headline {
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
  font-family: $helvetica-bold-font-family;
  @include swap_direction(margin, 2px 3px 2px);
  @include swap_direction(padding, 0 0 0 36px);
  color: $color-white;
  line-height: 37px;
  height: 36px;
  letter-spacing: 2px;
  text-align: #{$ldirection};
  text-indent: 30px;
  position: relative;
  background-color: $color-black;
  border-radius: 5px 5px 0 0;
}

.sticky-chat__icon {
  margin-#{$rdirection}: 3px;
  font-size: 15px;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  vertical-align: middle;
}

.sticky-chat__icon.icon-livechat {
  background: url('/media/images/livechat/chat_icon_black.png') 100% top no-repeat;
  display: inline-block;
  height: 32px;
  width: 50px;
  top: 14px;
  #{$rdirection}: 216px;
  @include swap_direction(margin, -10px 0 0);
}

#footer {
  .footer-bottom .block {
    .sticky-chat div {
      display: block;
    }
    .sticky-chat-content {
      display: block;
      background: #fff;
    }
    .sticky-chat-sub-wrapper {
      @include swap_direction(padding, 0 15px 15px);
      display: block;
      text-align: #{$ldirection};
      > h2 {
        color: $color-black;
        padding: 20px 0 15px 0;
        margin: 0;
        font-size: 35px;
        text-align: center;
        font-family: $base-light-font-family;
      }
      .sticky-content-title {
        text-align: left;
        font-weight: bold;
        letter-spacing: 2px;
      }
      .sticky-chat-product-details #lpbuttonproduct,
      .sticky-chat-order-details #lpbuttonordertech {
        text-align: center;
      }
      .sticky-chat-details .sticky-chat-title {
        font-size: 12px;
        line-height: 20px;
        padding: 0 0 10px 0px;
        color: $color-black;
        text-align: center;
        font-weight: bold;
        font-family: $helvetica-bold-font-family;
      }
      .product_info,
      .order_info {
        margin: 0 0 15px 0;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        color: $color-darker-grey;
      }
      .product_info {
        margin: 14px 0 15px 0;
      }
      .more_information {
        font-size: 11px;
        font-weight: bold;
        margin: 24px 0 20px 0;
        color: $color-darker-grey;
        a {
          font-size: 11px;
          font-weight: bold;
          text-decoration: underline;
        }
        a:hover {
          color: $color-darker-grey;
        }
      }
    }
  }
  .sticky-spacer {
    height: 69px;
    margin-top: 10px;
  }
  .sms-live__chat {
    .messenger {
      width: auto;
      @include swap_direction(margin, 0 30px 0 20px);
      a {
        @include swap_direction(margin, 0);
      }
    }
    .consultant {
      width: 20%;
      display: inline-block;
      font-family: $helvetica-regular-font-family;
      vertical-align: top;
      line-height: 21px;
      img {
        width: 21px;
        margin: 0 5px 0 0;
        vertical-align: middle;
      }
    }
    .tel {
      width: 12%;
    }
    .signup-forms {
      width: 37%;
    }
    form label {
      width: 51%;
    }
    .form-text {
      width: 32%;
    }
    .form-submit {
      width: 14%;
    }
  }
}
// mobile first approach
@media (min-width: 768px) {
  .sticky-chat {
    #{$rdirection}: 20px;
    width: 280px;
    bottom: -361px;
    h3.sticky-chat__headline {
      line-height: 37px;
      margin: 2px;
    }
  }
}
