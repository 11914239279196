// Redesigned customer service landing page styles.
@mixin customer_service_hub {
  .customer-service-hub {
    .editorial-grid-formatter {
      &:has(.cs-chat) {
        background-color: $cr19-bg-light;
        padding-top: 40px;
        padding-bottom: 28px;
      }
      &__content-wrapper {
        margin-top: 24px;
        padding: 0 4px;
        @media #{$cr19-medium-up} {
          margin-top: unset;
          padding: 0;
        }
      }
      &__item {
        .content-block {
          &__image {
            img {
              height: 50%;
              width: 12%;
            }
          }
          &__line {
            padding-bottom: 0;
            &--content {
              margin-top: 17px;
            }
            &--headline {
              padding-bottom: 24px;
            }
          }
          &__picture {
            padding: 0 0 10px;
            &:has(img[alt='chat-icon']),
            &:has(img[alt='call-us-icon']) {
              padding-bottom: 10px;
              text-align: center;
              img {
                height: auto;
                width: auto;
              }
            }
          }
        }
        &:has(.holiday-nodeblock-noderef) {
          border: 1px solid $color-grey;
          height: auto;
          margin: 12px 0;
          padding: 0;
          width: 100%;
          @media #{$cr19-medium-up} {
            border: unset;
            margin: initial;
            padding: 0 12px 12px;
          }
          a,
          .cs-chat {
            font-family: $nitti-regular-font-family;
          }
        }
        &:has(.basic-textarea-v1) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-top: 0;
          padding-bottom: 0;
          @media #{$cr19-medium-up} {
            flex-direction: row;
          }
          .elc-button {
            width: 100%;
            padding: 14px 24px;
            position: relative;
            @media #{$cr19-medium-up} {
              width: 260px;
            }
            span {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              #{$ldirection}: 0;
            }
          }
        }
      }
    }
    .holiday-nodeblock-noderef {
      border: 1px solid $color-grey;
      height: auto;
      .basic-tout {
        &__inner {
          padding: 0 24px;
          @media #{$cr19-medium-up} {
            padding: 24px;
          }
        }
      }
      &:has(img[alt='chat-icon']),
      &:has(img[alt='call-us-icon']) {
        height: 275px;
        padding: 20px 0;
        @media #{$cr19-medium-up} {
          padding: 0;
        }
      }
    }
    .hero-full-width {
      padding: 40px 24px;
      background-color: $cr19-bg-light;
      @media #{$cr19-medium-up} {
        padding: 40px 72px;
      }
      .chat-banner {
        display: inline-block;
        .LPMcontainer {
          @include elc-button--dark;
          line-height: 2.5 !important;
        }
      }
    }
    .breadcrumbs {
      padding: 16px 0 0;
      .breadcrumbs__level,
      .breadcrumbs__level a {
        color: $cr19-text-grey;
        font-size: 14px;
        letter-spacing: 0.02em;
        line-height: get-line-height(15px, 24px);
      }
      .breadcrumbs__level {
        display: inline;
        &::before {
          content: ' /';
        }
        &:first-child,
        &:last-child {
          &::before {
            content: '';
          }
        }
        &:nth-last-child(3) {
          display: inline;
        }
      }
    }
    .block-template-product-breadcrumbs-v1 {
      .editorial-grid-formatter__content-wrapper {
        margin-top: 0;
      }
      .editorial-grid-formatter__grid > div {
        padding-top: 0;
      }
    }
    .block-template-holiday-nodeblock-noderef-v1 {
      .editorial-grid-formatter__content-wrapper {
        margin-top: 0;
      }
    }
    .faq-formatter {
      &__title {
        display: none;
      }
      @media #{$cr19-medium-up} {
        padding: 0 80px;
      }
      &__content {
        .faq-content {
          flex-wrap: wrap;
          padding: 16px 0;
          &__button {
            flex: 0 0 100%;
          }
          &__answer {
            flex: 0 0 100%;
            &[aria-hidden='false'] {
              padding: 0;
              margin-top: 15px;
            }
            .body-text {
              font-size: 14px;
              .highlight {
                font-weight: 700;
                line-height: get-line-height(14px, 20px);
                margin-bottom: 15px;
              }
              .beauty-cs {
                display: inline-block;
              }
              p {
                margin-bottom: 15px;
                font-size: 14px;
              }
              ul {
                list-style-type: disc;
                padding-#{$ldirection}: 23px;
                margin-bottom: 15px;
              }
              a.cta {
                font-size: 16px;
                font-weight: 400;
                padding: 14px 27px;
                margin-bottom: 15px;
                height: auto;
                width: auto;
                min-width: auto;
                line-height: normal;
                border: 0;
              }
            }
          }
        }
      }
    }
  }
  #main:has(.customer-service-hub) {
    padding-bottom: 0;
  }
}
