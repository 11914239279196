.device-mobile {
  .single-video-v1 {
    border-bottom: 0px solid $color-white;
    .image-placeholder {
      .image-placeholder-img {
        display: block;
        width: 100%;
        max-width: auto;
      }
    }
    .image-title {
      display: none;
    }
  }
  .single-video {
    iframe {
      width: 100%;
    }
  }
}

.single-video-v1 {
  .video-wrapper {
    .video_player_container {
      .video-player-wrapper {
        .video_player {
          video {
            max-height: 806px;
          }
        }
      }
    }
  }
  .outer-wrap {
    max-width: 1920px;
  }
  .image-title {
    position: absolute;
    top: 80px;
    #{$ldirection}: unset;
    text-align: center;
    width: 100%;
  }
}

.outer-wrap {
  padding-#{$ldirection}: 0;
  padding-#{$rdirection}: 0;
}

body.section-foundation-finder {
  #foundation-finder-quiz {
    .ff-quiz__slide {
      height: 790px !important;
      @media #{$medium-up} {
        height: 632px !important;
      }
    }
    .foundation-finder_finish-selection {
      li {
        width: 33%;
      }
      :nth-child(2) {
        display: none;
      }
    }
    .foundation-finder_form-selection {
      width: 648px;
      li.Stick {
        display: none;
      }
    }
    .foundation-finder {
      .ff-quiz__slide--1 {
        .ff-quiz__slide-container {
          @include breakpoint($ab-small-down) {
            min-height: 795px;
          }
        }
      }
    }
  }
}
.registration_content {
  .email_promotions {
    .pc_email_promo_container {
      .label-content {
        font-family: $helvetica-regular-font-family;
      }
    }
  }
}
